@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #241e20;
  color: #eee;
  font-family: "Saira Extra Condensed", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
